
import React from 'react'
import './index.css'

const  ToneLoader =() => {
 
 
     return (
      
       <div  className='tone-loader'>
             <img src="/assets/toneloader.png" alt="toneLoader"  />
        </div>
  )
}

export default ToneLoader;
